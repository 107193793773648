import { getCurrentDate, globalFunctions } from "../global-functions/GlobalFunctions";
import { createTemplate } from "../pdf-template/OrderTransactionPdf";

const adminMailOptionsF = async(ordersArray, invoice,pricingStatus) => {
    let totalBill = 0;
    let productCount=0
    const orders = ordersArray
    for (let order of ordersArray) {
      const { bill, employeeProducts } = order;
        totalBill += bill;
      employeeProducts.forEach(val=>{
        productCount += Number(val.productQuantity) 
      })
  }
    
   const  data = {SNO:invoice,name:ordersArray[0].name,company:orders[0].companyName, noOfProducts:productCount,orders:orders,bill:totalBill,createdAt:getCurrentDate(),pricingStatus};
    // const pdfContent= await structureEmail(data)
    globalFunctions.sendPdfToServer(data,[ordersArray[0].managerEmail])
    // return {
    //   from: 'sys.notification77@gmail.com',
    //   to:[ordersArray[0].managerEmail,"subhan.akram1971@gmail.com"],
    //   subject: 'Neue Bestellung erstellt..!!!',
    //   text: 'Order',
    //   attachments: [
    //     {
    //       filename: 'order.pdf',
    //       content: pdfContent,
    //       encoding: 'base64',
    //     },
    //   ]}
    
  };
  const managerMailOptionsF = async(ordersArray, invoice,pricingStatus) => {
    let totalBill = 0;
    let productCount=0
    const orders = ordersArray
    for (let order of ordersArray) {
        const { bill, employeeProducts } = order;
          totalBill += bill;
        employeeProducts.forEach(val=>{
          productCount += Number(val.productQuantity) 
        })
    }
   
   const  data = {SNO:invoice,name:ordersArray[0].name,company:orders[0].companyName, noOfProducts:productCount,orders:orders,bill:totalBill,createdAt:getCurrentDate(),pricingStatus};
    // const pdfContent= await structureEmail(data)
    globalFunctions.sendPdfToServer(data,[ordersArray[0].managerEmail])
    
    // return  {
    //       from: 'sys.notification77@gmail.com',
    //       to:["subhan.akram1971@gmail.com","creativecrazeweb@gmail.com",ordersArray[0].managerEmail],
    //       subject: 'Neue Bestellung erstellt..!!!',
    //       text: 'Order',
    //       attachments: [
    //         {
    //           filename: 'order.pdf',
    //           content: pdfContent,
    //           encoding: 'base64',
    //         },
    //       ],}
   
  }

  
  const adminManagerEmployeeMailOptionsF = async(employeeEmail, managerEmail, company, orderInfo, pricingStatus,invoice,employee,comment) => {
    const employeeProducts=JSON.parse(orderInfo.employeeProducts)  
  const orders =[{employeeProducts,bill:orderInfo.bill,comment,employeeName:employee}]
   let productCount=0
   let totalBill=0
   employeeProducts.forEach(val=>{
    totalBill += (val.productPrice?Number(val?.productPrice):0) * Number(val?.productQuantity)
    productCount += Number(val.productQuantity)
   })
   const data = {SNO:invoice,name:employee,company, noOfProducts:productCount,orders:orders,bill:totalBill,createdAt:getCurrentDate(),pricingStatus};
    console.log("data===",data)
    // const pdfContent= await structureEmail(data)
    globalFunctions.sendPdfToServer(data,[managerEmail,employeeEmail])
    // return {
    //   from: 'sys.notification77@gmail.com',
    //   to:["subhan.akram1971@gmail.com",managerEmail],
    //   subject: 'admin manager employee email',
    //   text: 'Order',
    //   attachments: [
    //     {
    //       filename: 'order.pdf',
    //       content: pdfContent,
    //       encoding: 'base64',
    //     },
    //   ]}
    }
    
    const EmployeeMailOptionsF = async(invoice,employeeEmail, managerEmail, company, orderInfo, pricingStatus,employee,comment) => {
        const employeeProducts=JSON.parse(orderInfo.employeeProducts)  
        const orders =[{employeeProducts,bill:orderInfo.bill,comment,employeeName:employee}]
         let productCount=0
         let totalBill=0
         employeeProducts.forEach(val=>{
          totalBill += (val.productPrice?Number(val?.productPrice):0) * Number(val?.productQuantity)
          productCount += Number(val.productQuantity)
         })
        const  data = {SNO:invoice,name:employee,company, noOfProducts:productCount,
              orders:orders,bill:totalBill,createdAt:getCurrentDate(),pricingStatus,
      
          };
          console.log("data===",data)
          // const pdfContent= await createTemplate(data)
          globalFunctions.sendPdfToServer(data,[managerEmail,employeeEmail])
         
        
        }
          

export const createTemplateAndSendForEmployees=async(ordersArray,invoice)=>{
    for (let i = 0; i < ordersArray.length; i++) {
          let orderInfo = {
            bill: ordersArray[i].bill,
            employeeProducts: JSON.stringify(ordersArray[i].employeeProducts),
          };
          let mailOptions =await EmployeeMailOptionsF(
            invoice,
            ordersArray[i].employeeEmail,
            ordersArray[i].managerEmail,
            ordersArray[i].companyName,
            orderInfo,
            ordersArray[0].pricingStatus,
            ordersArray[i].employeeName,
            ordersArray[i]?.comment
  
          );
  
        //   sendEmail(mailOptions);
        }
}

export const  handleOrderEmails=(ordersArray,invoice,manager)=>{
   
    if(ordersArray[0].role==='manager'){
        createTemplateAndSendForEmployees(ordersArray,invoice)
         managerMailOptionsF(
              ordersArray,invoice,ordersArray[0].pricingStatus);
              adminMailOptionsF(
              ordersArray,
              invoice,
              ordersArray[0].pricingStatus
            );
    }else{
        const orderData=ordersArray[0]
        let orderInfo = {
            bill: ordersArray[0].bill,
            employeeProducts: JSON.stringify(ordersArray[0].employeeProducts),
          };
        EmployeeMailOptionsF(
            orderData.employeeEmail,
            manager[0].managerEmail,
            orderData.companyName,
            orderInfo,
            ordersArray[0].pricingStatus,
            invoice,
            orderData?.employeeName,
            orderData?.comment
          );
           adminManagerEmployeeMailOptionsF(
            orderData.employeeEmail,
            manager[0].managerEmail,
            orderData.companyName,
            orderInfo,
            ordersArray[0].pricingStatus,
            invoice,
            orderData?.employeeName,
            orderData?.comment
          );
    }
}