import styled from "styled-components"


export const AllProductStyle=styled.div`
 & .ant-upload-wrapper{
  
  & .ant-upload-list-item-container{
    display:none
  }
}
`