import {
  BsArchiveFill,
  BsCartPlusFill,
  BsClipboardDataFill,
  BsCurrencyExchange,
  BsFillGridFill,
  BsInfoSquare,
  BsPeopleFill,
} from "react-icons/bs";

export const managerSidebarData = (budgetStatus,t) => {
  const tempData = [
    {
      id: 1,
      icon: <BsFillGridFill />,
      label: t("Dashboard"),
      path: "",
    },
    {
      id: 2,
      icon: <BsClipboardDataFill />,
      label: t("Order Now"),
      path: "order-tab",
    },
    {
      id: 3,
      icon: <BsCartPlusFill />,
      label: t("Cart"),
      path: "cart",
    },
    {
      id: 4,
      icon: <BsPeopleFill />,
      label: t("Employees"),
      path: "employee",
    },
    {
      id: 5,
      icon: <BsInfoSquare />,
      label: t("Company Profile Detail"),
      path: "company-profile",
    },
    {
      id: 6,
      icon: <BsCurrencyExchange />,
      label: t("Request for Budget"),
      path: "budget-request",
    },

    {
      id: 7,
      icon: <BsArchiveFill />,
      label: t("Past Orders"),
      path: "past-order",
    },
  ];
  let data = [];
  if (!budgetStatus) {
    tempData.splice(5, 1);
    data = tempData;
  } else {
    data = [...tempData];
  }
  return data;
};

export const employeeSidebarData =(t)=>{
   return [
        {
          id: 2,
          icon: <BsClipboardDataFill />,
          label: t("Order Now"),
          path: "order-tab",
        },
        {
          id: 3,
          icon: <BsCartPlusFill />,
          label: t("Cart"),
          path: "cart",
        },
        {
          id: 5,
          icon: <BsInfoSquare />,
          label: t("Company Profile Detail"),
          path: "company-profile",
        },
      
        {
          id: 7,
          icon: <BsArchiveFill />,
          label: t("Past Orders"),
          path: "past-order",
        },
      ];
}
