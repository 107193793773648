import React from 'react';
import ReactPDF, { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { formatPriceForPdf} from '../global-functions/GlobalFunctions';
// Create styles
const styles = StyleSheet.create({
  page: {
    height: '100%',
    backgroundColor: 'transparent',
    padding: '16px',
  },
  table: {
    width: '100%',
    marginTop: '16px',
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeaderRow: {
    backgroundColor: '#fafafa',
  },

  tableHeaderCell: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.88)',
    flexDirection: 'row',
    border: '1px solid #f0f0f0',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '11px',
    paddingRight: '27px',
  },
  tableDataCell: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.88)',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '8px',
    paddingRight: '8px',
    border: '1px solid #f0f0f0',
    height: '40px',
  },
  span: {
    fontSize: '9px',
    paddingTop: '4px',
    paddingBottom: '4px',
    border: '1px solid #d9d9d9',
  },
  headerText: {
    fontSize: '10px',
  },
  textCoverBox: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    paddingTop: '7px',
    paddingBottom: '4px',
    paddingLeft: '10px',
    paddingRight: '10px',
    // background: "white",
    backgroundColor: '#f0f5ff',
    border: '1px solid #f0f0f0',
    color: 'rgba(0, 0, 0, 0.88)',
  },
  textCoverBox2: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    paddingTop: '7px',
    paddingBottom: '4px',
    paddingLeft: '10px',
    paddingRight: '10px',
    // background: "white",
    color: '#389e0d',
    backgroundColor: '#f6ffed',
    border: '1px solid #f0f0f0',
  },
  content: {
    marginTop: '10px',
    backgroundColor: '#D3D3D3',
    borderRadius: '8px',
    padding: '10px',
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  itemContainer: {
    width: 90,
    paddingBottom: '10px',
    // border:"1px solid red",
    marginTop: '3px',
  },
  itemMargin: {
    //    marginLeft:"10px",
  },
  box: {
    width: 85,
    height: 85,
    marginTop: '10px',
  },
  textContainer: {
    marginTop: '4px',
  },
  text: {
    fontSize: '9px',
    color: 'black',
    fontWeight: 'bold',
    //   lineHeight: 4,
  },
  headingText: {
    fontSize: '12px',
    color: 'black',
    fontWeight: 'bold',
  },

  image: {
    width: '100%',
    height: '100%',
  },
  pageHeader: { marginTop: '-10px', fontSize: '9px' },
  comment:{
    marginTop:"20px"
  }
});

// Create Document Component
export const MyDocument = ({ data }) => {
  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <Text
          style={styles.pageHeader}
          render={({ pageNumber, totalPages }) => pageNumber > 1 && `${pageNumber} / ${totalPages}`}
          fixed
        />

        <View style={styles.table}>
          <View style={[styles.tableRow]}>
            <View style={styles.tableRowCol}>
              <View style={styles.tableHeaderCell}>
                <Text style={styles.headerText}>Invoice</Text>
              </View>
              <View style={styles.tableDataCell}>
                <Text style={styles.textCoverBox}>
                  <Text style={styles.span}>{data?.SNO}</Text>
                </Text>
              </View>
            </View>
            <View style={styles.tableRowCol}>
              <View style={[styles.tableHeaderCell]}>
                <Text style={styles.headerText}>Ordered By</Text>
              </View>
              <View style={styles.tableDataCell}>
                <Text style={styles.textCoverBox2}>
                  <Text style={styles.span}>{data?.name}</Text>
                </Text>
              </View>
            </View>
            <View>
              <View style={styles.tableHeaderCell}>
                <Text style={styles.headerText}>Company</Text>
              </View>
              <View style={styles.tableDataCell}>
                <Text style={styles.textCoverBox}>
                  <Text style={styles.span}>{data?.company}</Text>
                </Text>
              </View>
            </View>
            <View>
              <View style={styles.tableHeaderCell}>
                <Text style={styles.headerText}>Nr. of Products</Text>
              </View>
              <View style={styles.tableDataCell}>
                <Text style={styles.textCoverBox2}>
                  <Text style={styles.span}>{data?.noOfProducts}</Text>
                </Text>
              </View>
            </View>
            <View>
              <View style={styles.tableHeaderCell}>
                <Text style={styles.headerText}>Bill</Text>
              </View>
              <View style={styles.tableDataCell}>
                <Text style={styles.textCoverBox}>
                  <Text style={styles.span}>{data.pricingStatus?`${formatPriceForPdf(data.bill)}`:"-"}</Text>
                </Text>
              </View>
            </View>
            <View>
              <View style={styles.tableHeaderCell}>
                <Text style={styles.headerText}>Created at</Text>
              </View>
              <View style={styles.tableDataCell}>
                <Text style={styles.textCoverBox2}>
                  <Text style={styles.span}>{data?.createdAt}</Text>
                </Text>
              </View>
            </View>
          </View>
        </View>
        {data.orders.map((val, i) => {
 
          return (
            <View key={val.employeeName} style={styles.content}>
              <View>
                <Text style={styles.headingText}>Employee : {val.employeeName}</Text>
              </View>
              <View style={styles.container}>
                {val.employeeProducts.map((valItem, i) => (
                  <View key={i} style={[styles.itemContainer, styles.itemMargin]}>
                    <View break style={styles.box}>
                      <Image style={styles.image} src={valItem.productImage} />
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>product: {valItem.productName}</Text>
                      <Text style={styles.text}>{val.pricingStatus? `price : ${formatPriceForPdf(valItem?.productPrice)}` : ""}</Text>
                      <Text style={styles.text}>{valItem.productSize ? `size: ${valItem.productSize}`: ""}</Text>
                      <Text style={styles.text}>{`quantity: ${valItem.productQuantity}`}</Text>
                    </View>
                  </View>
                ))}
               
              </View>
              <View>
                <Text style={[styles.comment,styles.text]}>Comment : {val.comment?val.comment: "No Comments"}</Text>

              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};
export const createTemplate= async (data) => {
  return await ReactPDF.renderToStream(<MyDocument data={data} />);
};
