import React, { useState } from "react";
import { message, Spin } from "antd";
import { MdOutlineFileDownload } from "react-icons/md";
import { baseURL } from "../config";
import {createTemplate,MyDocument} from "../pdf-template/OrderTransactionPdf"
import { pdf } from "@react-pdf/renderer";
const DownloadPDFButton = ({ row }) => {
  const [loading, setLoading] = useState(false);
  const sendPdfToServer = async (data) => {
    try {
      console.log("data==",data)
      // Generate PDF Blob
      const blob = await pdf(<MyDocument data={data} />).toBlob();
      // const formData = new FormData();
      // formData.append('file', blob, 'order.pdf');
      // const response = await fetch(`${baseURL}/gernal/pdfGenerate`, {
      //   method: 'POST',
      //   body: formData,
      // });
      // console.log("formdata===",formData)
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = data.SNO;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      console.log('PDF successfully sent to server');
    } catch (error) {
      console.error('Error sending PDF to server:', error);
    }
  };

  const downloadPDF = async () => {
    try {
      setLoading(true);
      let data=row
      const isManager = data.managerOrder.length;
      const orders = isManager
        ? data.managerOrder
        : [{ employeeProducts: data.orderInfo, name: data.name }];
      data = isManager
        ? { ...data, orders }
        : { ...data, orders: [{ ...orders[0], employeeName: orders[0].name }] };
       await sendPdfToServer(data)
      // const response = await fetch(`${baseURL}/gernal/pdfGenerate`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(pdfContent),
      // });

  

 
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button className="cursor-pointer" onClick={downloadPDF}>
      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <MdOutlineFileDownload style={{ fontSize: "22px", color: "black" }} />
      )}
    </button>
  );
};

export default DownloadPDFButton;
