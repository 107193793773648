import axios from "axios";

let url=process.env.REACT_APP_BACKEND || "https://system-backend-three.vercel.app/api";
// let url="https://system-backend-three.vercel.app/api";
// let url="https://system-backend-5imvp53g0-sys-order77.vercel.app/api"





export const baseURL = url;

export const API = axios.create({
  baseURL,
});

export const Headers = (token) =>{
  API.defaults.headers.post["Content-Type"] = "application/json";
  API.defaults.headers.post["token"] = localStorage.getItem("token");
  API.defaults.headers.get["token"] = localStorage.getItem("token");
  return token
    ? {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      }
    : {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
};
