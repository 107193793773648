import styled from "styled-components";

export const SystemSettingsStyle=styled.div`

 padding:2rem;
 & .system-lng-btn{
   gap:1.5rem;
   
}
.selected-lng{
    font-weight: bold;
    margin-top:4rem;
}
`